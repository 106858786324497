var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", staticStyle: { "min-height": "105px" } },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-text" }, [
          _c(
            "div",
            { staticClass: "row align-items-center justify-content-between" },
            [
              _vm._m(0),
              _c("div", { staticClass: "col-md-7" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "medium",
                      "font-weight": "600",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(this.user.name.first) +
                        " " +
                        _vm._s(this.user.name.last)
                    ),
                  ]
                ),
                _c("div", { staticClass: "mt-1" }, [
                  _vm._v(" " + _vm._s(this.user.email) + " "),
                ]),
                this.user.accessLevel === _vm.accessLevels.ADMIN
                  ? _c(
                      "div",
                      { staticClass: "mt-1" },
                      [
                        _c(
                          "b-badge",
                          {
                            staticStyle: { "font-size": "12px" },
                            attrs: { variant: "success" },
                          },
                          [_vm._v(" " + _vm._s(this.user.accessLevel) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "div",
                { staticClass: "col-md-2 text-right" },
                [
                  _c(
                    "b-dropdown",
                    {
                      attrs: { size: "sm", variant: "light", dropright: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "button-content",
                          fn: function () {
                            return [
                              _c("i", {
                                staticClass: "bx bx-dots-vertical-rounded",
                                staticStyle: { "font-size": "large" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clickResendInvitation()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "bx bx-sync mr-1" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.manageUsersWidget.pendingUserCard.resendInvitationButton"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-dropdown-item",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.clickDeletInvitation()
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "bx bx-trash mr-1" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.manageUsersWidget.pendingUserCard.deleteInvitationButton"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
      _vm.isDisplayDeleteUserOverlay === true
        ? _c("DeleteUserOverlay", {
            attrs: { visible: _vm.isDisplayDeleteUserOverlay, user: this.user },
            on: {
              close: function ($event) {
                _vm.isDisplayDeleteUserOverlay = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayResendUserInvitationOverlay === true
        ? _c("ResendUserInvitationOverlay", {
            attrs: {
              visible: _vm.isDisplayResendUserInvitationOverlay,
              user: this.user,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayResendUserInvitationOverlay = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-3" }, [
      _c("img", {
        staticClass: "rounded-circle",
        staticStyle: { height: "60px" },
        attrs: {
          src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }