var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col-md-11" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.resetPasswordLinkOverlay.title")
                  ) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
      ]),
      _c(
        "b-alert",
        {
          staticClass: "mt-3 mb-3",
          attrs: { variant: "danger", dismissible: "" },
          model: {
            value: _vm.isSendResetPasswordLinkError,
            callback: function ($$v) {
              _vm.isSendResetPasswordLinkError = $$v
            },
            expression: "isSendResetPasswordLinkError",
          },
        },
        [_vm._v(_vm._s(_vm.sendResetPasswordLinkErrorMessage))]
      ),
      _c(
        "b-alert",
        {
          staticClass: "mt-3 mb-3",
          attrs: { variant: "success" },
          model: {
            value: _vm.isSendResetPasswordLinkSuccess,
            callback: function ($$v) {
              _vm.isSendResetPasswordLinkSuccess = $$v
            },
            expression: "isSendResetPasswordLinkSuccess",
          },
        },
        [
          _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "adminComponents.resetPasswordLinkOverlay.successMessage"
                )
              ) +
              " "
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "row align-items-center justify-content-between" },
        [
          _c("div", { staticClass: "col-md-2" }, [
            _vm.user && _vm.user.image
              ? _c("img", {
                  staticClass: "rounded-circle",
                  staticStyle: { height: "60px" },
                  attrs: { src: _vm.user.image },
                })
              : _c("img", {
                  staticClass: "rounded-circle",
                  staticStyle: { height: "60px" },
                  attrs: {
                    src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/default-avatar.png",
                  },
                }),
          ]),
          _c("div", { staticClass: "col-md-10" }, [
            _c(
              "div",
              { staticStyle: { "font-size": "medium", "font-weight": "600" } },
              [
                _vm._v(
                  " " +
                    _vm._s(this.user.name.first) +
                    " " +
                    _vm._s(this.user.name.last)
                ),
              ]
            ),
            _c("div", { staticClass: "mt-1" }, [
              _vm._v(" " + _vm._s(this.user.email) + " "),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "row mt-4" }, [
        _c("div", { staticClass: "col-md-12" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("adminComponents.resetPasswordLinkOverlay.description")
              ) +
              " "
          ),
        ]),
      ]),
      _c("div", { staticClass: "row mt-4 mb-3" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              {
                attrs: { variant: "primary" },
                on: { click: _vm.clickSendResetPasswordLink },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.resetPasswordLinkOverlay.sendButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
            _c(
              "b-button",
              {
                staticClass: "ml-2",
                attrs: { variant: "light" },
                on: { click: _vm.clickClose },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "adminComponents.resetPasswordLinkOverlay.cancelButton"
                      )
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }