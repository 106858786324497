var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card", staticStyle: { height: "610px" } }, [
    _vm.isComponentLoading
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "card-text" }, [
            _c("div", { staticClass: "row pt-5" }, [
              _c(
                "div",
                { staticClass: "col-md-12 text-center p-5" },
                [
                  _c("b-spinner", {
                    staticClass: "m-2",
                    staticStyle: { width: "5rem", height: "5rem" },
                    attrs: { variant: "mid", role: "status" },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.isComponentLoading && this.creditPlans && this.creditPlans.length > 0
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "card-text" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "font-size": "medium",
                      "font-weight": "600",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("adminComponents.creditPlansWidget.title")
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
            _c("div", { staticClass: "row mt-4" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("b-form-input", {
                    attrs: {
                      type: "search",
                      placeholder: _vm.$t(
                        "adminComponents.creditPlansWidget.searchPlaceholder"
                      ),
                    },
                    model: {
                      value: _vm.filter,
                      callback: function ($$v) {
                        _vm.filter = $$v
                      },
                      expression: "filter",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-9 text-right" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "margin-right": "10px",
                        "text-align": "right",
                      },
                      on: { change: _vm.setDataForTable },
                      model: {
                        value: _vm.showAllActiveCreditPlans,
                        callback: function ($$v) {
                          _vm.showAllActiveCreditPlans = $$v
                        },
                        expression: "showAllActiveCreditPlans",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.creditPlansWidget.creditPlanStatus.active"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "margin-right": "10px",
                        "text-align": "right",
                      },
                      on: { change: _vm.setDataForTable },
                      model: {
                        value: _vm.showAllExpiredCreditPlans,
                        callback: function ($$v) {
                          _vm.showAllExpiredCreditPlans = $$v
                        },
                        expression: "showAllExpiredCreditPlans",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.creditPlansWidget.creditPlanStatus.expired"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticStyle: {
                        display: "inline-block",
                        "margin-right": "10px",
                        "text-align": "right",
                      },
                      on: { change: _vm.setDataForTable },
                      model: {
                        value: _vm.showAllUpcomingCreditPlans,
                        callback: function ($$v) {
                          _vm.showAllUpcomingCreditPlans = $$v
                        },
                        expression: "showAllUpcomingCreditPlans",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.creditPlansWidget.creditPlanStatus.upcoming"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mt-4" }, [
              _c(
                "div",
                { staticClass: "table-responsive mb-0" },
                [
                  _c("b-table", {
                    attrs: {
                      items: _vm.dataForTable,
                      fields: _vm.fieldsForTable,
                      responsive: "sm",
                      "per-page": _vm.perPage,
                      "current-page": _vm.currentPage,
                      bordered: true,
                      filter: _vm.filter,
                      "filter-included-fields": ["description"],
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(isRunning)",
                          fn: function (scope) {
                            return [
                              scope.item.id ===
                              _vm.nextAvailableCreditPlanForCompany?.id
                                ? _c("i", {
                                    staticClass:
                                      "bx bxs-circle bx-flashing text-success",
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "cell(date)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatDate(data.value)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(balance)",
                          fn: function (scope) {
                            return [
                              scope.item.isPayPerUsage
                                ? _c(
                                    "span",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "font-size-12",
                                          attrs: { variant: "blue2", pill: "" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "adminComponents.creditPlansWidget.payAsUsage"
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formatCredit(scope.item.balance)
                                        ) +
                                        " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                        {
                          key: "cell(received)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatCredit(data.value)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(status)",
                          fn: function (data) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-soft-secondary font-size-12",
                                  class: {
                                    "badge-soft-success":
                                      `${data.value}` ===
                                      _vm.creditPlanStausEnum.ACTIVE,
                                    "badge-soft-danger":
                                      `${data.value}` ===
                                      _vm.creditPlanStausEnum.EXPIRED,
                                    "badge-soft-warning":
                                      `${data.value}` ===
                                      _vm.creditPlanStausEnum.UPCOMING,
                                  },
                                },
                                [
                                  data.value === _vm.creditPlanStausEnum.ACTIVE
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "adminComponents.creditPlansWidget.creditPlanStatus.active"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  data.value === _vm.creditPlanStausEnum.EXPIRED
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "adminComponents.creditPlansWidget.creditPlanStatus.expired"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  data.value ===
                                  _vm.creditPlanStausEnum.UPCOMING
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "adminComponents.creditPlansWidget.creditPlanStatus.upcoming"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(startDate)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.formatDate(data.value)) + " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(expirationDate)",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.item.isExpiring
                                      ? _vm.formatDate(
                                          scope.item.expirationDate
                                        )
                                      : "-"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: "cell(pricePerCredit)",
                          fn: function (data) {
                            return [
                              data.value > 0
                                ? _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.formatCurrency(data.value)) +
                                        " "
                                    ),
                                  ])
                                : _c("span", [_vm._v("-")]),
                            ]
                          },
                        },
                        {
                          key: "cell(invoice)",
                          fn: function (data) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(data.value ? data.value : "-") +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2022957508
                    ),
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right",
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "pagination pagination-rounded mb-0" },
                      [
                        _c("b-pagination", {
                          attrs: {
                            "total-rows": _vm.rows,
                            "per-page": _vm.perPage,
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    !_vm.isComponentLoading &&
    (!this.creditPlans || this.creditPlans.length === 0)
      ? _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "card-text" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 text-center pt-5" }, [
                _c("img", {
                  staticClass: "img-fluid mb-4 mt-3",
                  staticStyle: { width: "60px" },
                  attrs: { src: require("@/assets/images/information.png") },
                }),
                _c("h6", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.creditPlansWidget.noCreditPlansToDisplay.title"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.creditPlansWidget.noCreditPlansToDisplay.text1"
                        )
                      )
                  ),
                  _c("br"),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.creditPlansWidget.noCreditPlansToDisplay.text2"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }