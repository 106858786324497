<script>
import CompanyService from "@/api/services/company.service.js";
import { getErrorMessageByErrorCode, getCreditsAccoringTolanguage, getDateAccoringTolanguage, calculateRemainingNumberOfDays } from "@/shared/util/helpers.js";
import ActivationStatusEnum from "@/shared/enums/activationStatus";
import ContractTypesEnum from "@/shared/enums/contractTypes";

export default {
  data() {
    return {
      /***** Data variables *****/
      organizations: null,
      dataForTable: null,
      activationStatus: ActivationStatusEnum,
      contractTypes: ContractTypesEnum,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      /***** Table Data *****/
      fieldsForTable: [
        {
          key: "id",
          label: "ID",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "createdDate",
          label: "Created Date",
          thClass: "d-none",
          tdClass: "d-none"
        },
        {
          key: "organization",
          label: "Organization",
          sortable: true,
          thStyle: { width: "250px" }
        },
        {
          key: "wallet",
          label: "Active Balance",
          thStyle: { width: "120px" }
        },
        {
          key: "walletBalanceAsPercentage",
          label: "Balance as %",
          thStyle: { width: "80px" }
        },
        {
          key: "organizationStatus",
          sortable: true,
          label: "Organization Status",
          thStyle: { width: "100px" }
        },
        {
          key: "contractType",
          sortable: true,
          label: "Contract Type",
          thStyle: { width: "120px" }
        },
        {
          key: "contractStartDate",
          label: "Contract Starts On",
          thStyle: { width: "100px" }
        },
        {
          key: "contractEndDate",
          label: "Contract Ends On",
          thStyle: { width: "100px" }
        },
        {
          key: "remainingDays",
          sortable: true,
          label: "Remaining Days",
          thStyle: { width: "100px" }
        },
        {
          key: "supportLevel",
          sortable: true,
          label: "Support Level",
          thStyle: { width: "50px" }
        },
        {
          key: "latestDataImport",
          sortable: true,
          label: "Latest Import",
          thStyle: { width: "110px" }
        }
      ],
      currentPage: 1,
      perPage: 5,
      filter: null
    }
  },
  computed: {
    rows() {
      return this.organizations.length;
    },
  },
  async mounted() {
    this.isComponentLoading = true;
    await this.fetchActiveOrganizations();

    // set current page
    this.currentPage = window.sessionStorage.getItem("PaginationAdminPanelOrganizationTabel") || 1;

    this.isComponentLoading = false;
  },
  methods: {
    async fetchActiveOrganizations() {
      try {
        this.dataForTable = []
        this.organizations   = await CompanyService.getAllActiveCompanies();

        // inster data into table
        this.organizations.forEach(async organization => {
          let organizationToPush = {
            id: organization.id,
            organization: {
              name: organization.name,
              image: organization.image
            },
            owner: {
              id: organization.companyOwner.id,
              name: organization.companyOwner.name.first + ' ' + organization.companyOwner.name.last,
              email: organization.companyOwner.email,
              image: organization.companyOwner.image,
            },
            organizationStatus: organization.isActivated,
            contractType: organization.contract.type,
            contractStartDate: organization.contract.startDate,
            contractEndDate: organization.contract.endDate,
            remainingDays: await calculateRemainingNumberOfDays(organization.contract.endDate),
            wallet: organization.wallet,
            primaryCreditPlan: organization.contract.primaryCreditPlan,
            backupCreditPlan: organization.contract.backupCreditPlan,
            supportLevel: organization.supportLevel,
            activeCreditOverview: {
              totalActiveBalance: organization.activeCreditOverview.totalActiveBalance,
              totalActiveReceived: organization.activeCreditOverview.totalActiveReceived,
              totalReceivedFromPayAsUsage: organization.activeCreditOverview.totalReceivedFromPayAsUsage,
              totalActiveBalancePercentage: (organization.activeCreditOverview.totalActiveBalance / organization.activeCreditOverview.totalActiveReceived) * 100
            },
            latestDataImport: organization.latestDataImport
          }

          this.dataForTable.push(organizationToPush)
        });
        
      } catch (error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    clickOrganizationinfo(organization) {
      this.$router.push({
        path: "/organization-details",
        query: { organization: organization.id }
      })
    },
    changePagination(pageNumber) {
      window.sessionStorage.setItem("PaginationAdminPanelOrganizationTabel", pageNumber);
    },
    formatCredit(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    getBalancePercentageVariant(percentage) {
      if (percentage <= 10) return 'danger'
      if (percentage <= 50) return 'warning'
      return 'success'
    },
    getContractTypeVariant(contractType) {
      if (contractType === this.contractTypes.STARTER) return 'factor'
      if (contractType === this.contractTypes.PROFESSIONAL) return 'silver'
      if (contractType === this.contractTypes.ENTERPRISE) return 'gold'
      if (contractType === this.contractTypes.CUSTOM) return 'fancy'
      return 'secondary'
    }
  }
}
</script>

<template>
  <div>

    <!-- Component Loading -->
    <div
      class="row pt-5"
      v-if="isComponentLoading"
    >
      <div class="col-md-12 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display active organizations in Table -->
    <div v-if="!isComponentLoading && organizations && organizations.length > 0" class="mt-4">
      <!-- Search Bar -->
      <div class="row mb-4">
        <div class="col-md-3">
          <b-form-input
            v-model="filter"
            type="search"
            placeholder="Search"
          ></b-form-input>
        </div>
      </div>

      <!-- Data Table -->
      <div class="table-responsive mb-0">
        <b-table
          :items="dataForTable"
          :fields="fieldsForTable"
          responsive="sm"
          hover
          :per-page="perPage"
          :current-page="currentPage"
          :bordered="true"
          :filter="filter"
          :filter-included-fields="['organization']"
          @row-clicked="clickOrganizationinfo($event)"
        >
          <!-- Organization Column -->
          <template v-slot:cell(organization)="data">
            <div class="row align-items-center">
              <div class="col-md-12">
                <img
                  :src="data.value.image"
                  class="rounded-circle"
                  style="height: 30px"
                />
                <span style="font-weight: 600"> {{ data.value.name }} </span>
              </div>
            </div>
          </template>

          <!-- Organization Wallet -->
          <template v-slot:cell(wallet)="{ item }">
            <template v-if="item.activeCreditOverview.totalActiveBalance">
              {{ formatCredit(item.activeCreditOverview.totalActiveBalance) }}
            </template>
            <template v-else-if="item.activeCreditOverview.totalReceivedFromPayAsUsage">
              <b-badge class="font-size-12 mb-2" variant="blue2" pill>
                {{ $t("adminComponents.creditPlansWidget.payAsUsage") }}
              </b-badge>
              <br/>
              - {{ formatCredit(item.activeCreditOverview.totalReceivedFromPayAsUsage) }}
            </template>
            <template v-else>
              0
            </template>
          </template>

          <!-- Balance as % -->
          <template v-slot:cell(walletBalanceAsPercentage)="{ item }">
            <b-badge
              v-if="item.activeCreditOverview.totalActiveBalancePercentage"
              class="font-size-12 mb-2" 
              :variant="getBalancePercentageVariant(item.activeCreditOverview.totalActiveBalancePercentage)" 
              pill
            >
              {{ Math.round(item.activeCreditOverview.totalActiveBalancePercentage) }}%
            </b-badge>
            <span v-else> - </span>
          </template>

          <!-- Organization Status -->
          <template v-slot:cell(organizationStatus)="data">
            <span
              class="badge badge-soft-secondary font-size-12"
              :class="{
                'badge-soft-warning': `${data.value}` === activationStatus.PENDING,
                'badge-soft-success': `${data.value}` === activationStatus.ACTIVATED,
                'badge-soft-danger': `${data.value}` === activationStatus.DEACTIVATED,
              }"
            >
              <span v-if="data.value === activationStatus.PENDING"> PENDING </span>
              <span v-if="data.value === activationStatus.ACTIVATED"> ACTIVATED </span>
              <span v-if="data.value === activationStatus.DEACTIVATED"> DEACTIVATED </span>
            </span>
          </template>

          <!-- Contract Type -->
          <template v-slot:cell(contractType)="scope">
            <span v-if="scope.item.remainingDays >= 0">
              <b-badge
                class="font-size-12 mb-2" 
                :variant="getContractTypeVariant(scope.item.contractType)" 
              >
                {{ scope.item.contractType }}
              </b-badge>
            </span>
            <span class="ml-1" v-else> - </span>
          </template>

          <!-- Contract Start Date -->
          <template v-slot:cell(contractStartDate)="scope">
            <span v-if="scope.item.remainingDays >= 0"> {{ formatDate(scope.item.contractStartDate) }} </span>
            <span v-else> - </span>
          </template>

          <!-- Contract End Date -->
          <template v-slot:cell(contractEndDate)="scope">
            <span v-if="scope.item.remainingDays >= 0"> {{ formatDate(scope.item.contractEndDate) }} </span>
            <span v-else> - </span>
          </template>

          <!-- Remaining Days -->
          <template v-slot:cell(remainingDays)="scope">
            <span v-if="new Date(scope.item.contractStartDate) > new Date()" class="badge badge-soft-factor font-size-12">Not yet started</span>
            <span v-else-if="scope.item.remainingDays < 0"> - </span>
            <span v-else> {{ scope.item.remainingDays }} </span>
          </template>

          <!-- Support Level -->
          <template v-slot:cell(supportLevel)="data">
            <span
              class="badge badge-soft-secondary font-size-12 rounded-pill"
              :class="{
                'badge-soft-success': `${data.value}` === 'A',
                'badge-soft-blue2': `${data.value}` === 'B',
                'badge-soft-factor': `${data.value}` === 'C',
              }"
            >
              {{ data.value }}
            </span>
          </template>

          <!-- Latest Data Import -->
          <template v-slot:cell(latestDataImport)="data">
            <span v-if="data.value"> 
              <span v-if="data.value.daysAgo === 0"> Today </span>
              <span v-else> {{ data.value.daysAgo }} days ago </span>
            </span>
            <span v-else> - </span>
          </template>
        </b-table>
      </div>

      <!-- pagination -->
      <div class="row">
        <div class="col">
          <div
            class="
              dataTables_paginate
              paging_simple_numbers
              float-right
            "
          >
            <ul class="pagination pagination-rounded mb-0">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                @change="changePagination"
              ></b-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
