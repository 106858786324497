<script>
import { EventBus } from "@/shared/util/event-bus.js";
import ContractService from "@/api/services/contract.service.js";
import CreditPlanService from "@/api/services/creditPlan.service.js";
import { calculateRemainingNumberOfDays, getErrorMessageByErrorCode, getDateAccoringTolanguage, getCreditsAccoringTolanguage, getCurrencyAccoringTolanguage, calculateNumberOfDaysBetweenTwoDates, getNumbersAccoringTolanguage } from "@/shared/util/helpers.js";
import { CreditBalanceChart } from "../charts.js"
import ContractStatus from '@/shared/enums/contractStatus.js';
import EditContractOverlay from '../overlays/edit-contract-overlay.vue';
import DeleteContractOverlay from '../overlays/delete-contract-overlay.vue';

export default {
  props: {
    contract: Object,
    organizationActiveContract: Object,
    isSuperAdminComponent: Boolean
  },
  components: {
    EditContractOverlay,
    DeleteContractOverlay
  },
  data() {
    return {
      /***** Data variables *****/
      contractDetails: null,
      creditBalanceChartOptions: null,
      creditBalancePercentage: [0],
      contractStatusEnum: ContractStatus,
      contractToDelete: null,
      nextAvailableCreditPlanForCompany: null,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      dataFetchingnErrorMessage: null,
      isDisplayEditContractOverlay: false,
      isDisplayDeleteContractOverlay: false
    }
  },
  watch: {
    contract: function() {
      this.fetchContractDetails();
    }
  },
  created() {
    EventBus.$on("refreshOrganizationContractsWidgets", async () => {
      await this.fetchContractDetails();
    })

    EventBus.$on("deleteContract", (contract) => {
      this.contractToDelete = contract;
      this.isDisplayDeleteContractOverlay = true;
    });
  },
  async mounted() {
    this.creditBalanceChartOptions = new CreditBalanceChart();
    await this.fetchContractDetails();
  },
  methods: {
    async fetchContractDetails() {
      this.isComponentLoading = true;
      try {
        this.contractDetails = null;

        this.contractDetails = await ContractService.getContractDetails(this.contract.id);
        await this.fetchNextAvailableCreditPlanForCompany();
        await this.setValueToChart();
        this.isComponentLoading = false;
        EventBus.$emit("contractComponetGetLoaded");
      } catch(error) {
        this.isComponentLoading = false;
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setValueToChart() {
      this.creditBalancePercentage = []
      this.creditBalancePercentage.push((this.contractDetails.primaryCreditPlan.credits.balance / this.contractDetails.primaryCreditPlan.credits.received) * 100);
    },
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    formatCurrency(currencyToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCurrencyAccoringTolanguage(currencyToFormat, langaugeToFormat);
    },
    getNumberOfDays(endDate) {
      return calculateRemainingNumberOfDays(endDate);
    },
    formatCredits(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    formatNumber(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getNumbersAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    getNumberOfDaysBetweenTwoDates(startDate, endDate) {
      return calculateNumberOfDaysBetweenTwoDates(startDate, endDate);
    },
    clickEditContract() {
      this.isDisplayEditContractOverlay = true;
    },
    async fetchNextAvailableCreditPlanForCompany() {
      try {
        this.nextAvailableCreditPlanForCompany = null;
        this.nextAvailableCreditPlanForCompany = await CreditPlanService.getNextAvailableCreditPlanForCompany(this.contract.assignedCompany);
      } catch(error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    }
  }
}
</script>

<template>
  <div class="card" style="min-height: 610px">

    <!-- Component Loading -->
    <div class="card-body" v-if="isComponentLoading">
      <div class="card-text">
        <div class="row pt-5">
          <div class="col-md-12 text-center p-5">
            <b-spinner
              style="width: 5rem; height: 5rem"
              class="m-2"
              variant="mid"
              role="status"
            ></b-spinner>
          </div>
        </div>
      </div>
    </div>

    <!-- Display Data -->
    <div class="card-body" v-if="!isComponentLoading && this.contractDetails">
      <div class="card-text">

        <div class="row">
          <div class="col-md-12">
            <span style="font-size: medium; font-weight: 600"> {{contractDetails.type}} </span>
            <span v-if="contractDetails.status !== contractStatusEnum.EXPIRED && isSuperAdminComponent"> <i class='bx bxs-pencil ml-2' style="font-size: medium; cursor: pointer;" @click="clickEditContract"></i> </span>
          </div>
        </div>

        <!-- Contract Dates -->
        <div class="row mt-3">
          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.startDate")}}: <strong> {{ formatDate(contractDetails.startDate) }} </strong>
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.endDate")}}: <strong> {{ formatDate(contractDetails.endDate) }} </strong>
          </div>

          <div class="col-md-3" v-if="contractDetails.status === contractStatusEnum.ACTIVE">
            {{$t("adminComponents.contractsWidget.contractDetails.remainingDays")}}: <strong> {{ getNumberOfDays(contractDetails.endDate) }} </strong>
          </div>

          <div class="col-md-3" v-if="contractDetails.status !== contractStatusEnum.ACTIVE">
            {{$t("adminComponents.contractsWidget.contractDetails.duration")}}: <strong> {{ getNumberOfDaysBetweenTwoDates(contractDetails.startDate, contractDetails.endDate) }} {{$t("adminComponents.contractsWidget.contractDetails.days")}} </strong>
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.renewsItSelft")}}:
            <span
              class="badge badge-soft-secondary font-size-12 ml-1"
              :class="{
                'badge-soft-success': contractDetails.autoRenew === true,
                'badge-soft-danger': contractDetails.autoRenew === false,
              }"
            >
              <span v-if="contractDetails.autoRenew"> {{$t("general.yes")}} </span>
              <span v-else> {{$t("general.no")}} </span>
            </span>
          </div>
        </div>

        <!-- Data Record & Data Import State -->
        <div class="row mt-2">
          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.numberOfDataImports")}}: <strong> {{ formatNumber(contractDetails.numberOfDatafiles) }} </strong>
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.numberOfDataRecords")}}: <strong> {{ formatNumber(contractDetails.numberOfDataRecords) }} </strong>
          </div>
        </div>

        <hr />

        <!-- Create by & At -->
        <div class="row mt-2" v-if="isSuperAdminComponent">
          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.createdAt")}}: <strong> {{ formatDate(contractDetails.createdAt)}} </strong>
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.createdBy")}}: <strong> {{contractDetails.createdBy}} </strong>
          </div>

          <div class="col-md-3">
            {{$t("adminComponents.contractsWidget.contractDetails.reference")}}: <strong> {{contractDetails.name}} </strong>
          </div>
        </div>

        <!-- Credit Plans -->
        <div class="row mt-5">
          <!-- Primary Credit Plan -->
          <div class="col-md-4" v-if="contractDetails.primaryCreditPlan">
            <div class="custom-credit-plan-card" :style="{backgroundColor: nextAvailableCreditPlanForCompany?.id === contractDetails.primaryCreditPlan.id ? '#f0fdf4' : 'white'}">

              <i
                v-if="nextAvailableCreditPlanForCompany?.id === contractDetails.primaryCreditPlan.id"
              class="bx bxs-circle bx-flashing text-success mr-1"
              ></i>
              <span style="font-size: normal; font-weight: 600"> {{$t("adminComponents.contractsWidget.contractDetails.primaryCreditPlan")}} </span> - 
              {{ contractDetails.primaryCreditPlan.name }}

              <!-- Display credit information for regular credti plans -->
              <div v-if="!contractDetails.primaryCreditPlan.isPayPerUsage">
                <!-- Wallet Chart -->
                <div class="row mt-1">
                  <div class="col-12 text-center">
                    <apexchart
                      ref="CreditBalanceChart"
                      class="apex-charts apexChartData"
                      type="radialBar"
                      height="250"
                      dir="ltr"
                      :series="this.creditBalancePercentage"
                      :options="creditBalanceChartOptions.chartOptions"
                    ></apexchart>
                  </div>
                </div>

                <!-- Active Credit Data -->
                <div class="row mt-2 mb-1">
                  <div class="col-6 text-center">
                    {{$t("adminComponents.contractsWidget.contractDetails.remaning")}}
                    <div style="font-size: normal; font-weight: 600"> {{ formatCredits(contractDetails.primaryCreditPlan.credits.balance) }} </div>
                  </div>
                  <div class="col-6 text-center">
                    {{$t("adminComponents.contractsWidget.contractDetails.received")}}
                    <div style="font-size: normal; font-weight: 600"> {{ formatCredits(contractDetails.primaryCreditPlan.credits.received) }} </div>
                  </div>
                </div>
              </div>

              <!-- Display credit information for pay-as-usage credit plans -->
              <div v-if="contractDetails.primaryCreditPlan.isPayPerUsage">
                <!-- Credit Usage -->
                <div class="row mt-5">
                  <div class="col-12 text-center">
                    {{$t("adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.usage")}}
                    <div style="font-size: xx-large; font-weight: 600"> {{ formatCredits(contractDetails.primaryCreditPlan.credits.received) }} </div>
                  </div>
                </div>

                <hr/>

                <!-- Payment Inforamtion -->
                <div class="row mt-4">
                  <div class="col-12 text-center">
                    {{$t("adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.title")}}
                    <div style="font-size: x-large; font-weight: 600"> € {{ formatCurrency(contractDetails.primaryCreditPlan.credits.received*contractDetails.primaryCreditPlan.pricePerCredit) }} </div>
                    <small> {{$t("adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.description")}} </small>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <!-- Backup Credit Plan -->
          <div class="col-md-4" v-if="contractDetails.backupCreditPlan">
            <div class="custom-credit-plan-card" :style="{backgroundColor: nextAvailableCreditPlanForCompany?.id === contractDetails.backupCreditPlan.id ? '#f0fdf4' : 'white'}">
              
              <i
                v-if="nextAvailableCreditPlanForCompany?.id === contractDetails.backupCreditPlan.id"
                class="bx bxs-circle bx-flashing text-success mr-1"
              ></i>
              <span style="font-size: normal; font-weight: 600"> {{$t("adminComponents.contractsWidget.contractDetails.backupCreditPlan.title")}} </span>
              - {{ contractDetails.backupCreditPlan.name }}
              <span v-b-tooltip.hover.right.v-light :title="$i18n.t('adminComponents.contractsWidget.contractDetails.backupCreditPlan.description')"> <i class='bx bx-info-circle' ></i> </span>

              <!-- Credit Usage -->
              <div class="row mt-5">
                <div class="col-12 text-center">
                  {{$t("adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.usage")}}
                  <div style="font-size: xx-large; font-weight: 600"> {{ formatCredits(contractDetails.backupCreditPlan.credits.received) }} </div>
                </div>
              </div>

              <hr/>

              <!-- Payment Inforamtion -->
              <div class="row mt-4">
                <div class="col-12 text-center">
                  {{$t("adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.title")}}
                  <div style="font-size: x-large; font-weight: 600"> € {{ formatCurrency(contractDetails.backupCreditPlan.credits.received*contractDetails.backupCreditPlan.pricePerCredit) }} </div>
                  <small> {{$t("adminComponents.contractsWidget.contractDetails.payAsUsageCreditPlan.invoice.description")}} </small>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>

    <!-- Edit Contract Overlay -->
    <EditContractOverlay
      v-if="isDisplayEditContractOverlay === true"
      :visible="isDisplayEditContractOverlay"
      @close="isDisplayEditContractOverlay = false"
      :contractToEdit="contractDetails"
      :organizationActiveContracts="organizationActiveContract"
    />

    <!-- Delete Contract Overlay -->
    <DeleteContractOverlay
      v-if="isDisplayDeleteContractOverlay === true"
      :visible="isDisplayDeleteContractOverlay"
      @close="isDisplayDeleteContractOverlay = false"
      :contractToDelete="contractToDelete"
    />
  </div>
</template>

<style scoped>
.custom-credit-plan-card {
  cursor: pointer;
  padding: 10px;
  min-height: 350px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
</style>