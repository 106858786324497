var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        centered: "",
        "hide-footer": "",
        "hide-header": "",
        "no-close-on-backdrop": "",
        size: "md",
      },
      model: {
        value: _vm.showModal,
        callback: function ($$v) {
          _vm.showModal = $$v
        },
        expression: "showModal",
      },
    },
    [
      _c("div", { staticClass: "row d-flex align-items-center" }, [
        _c("div", { staticClass: "col-md-11" }, [
          _c(
            "div",
            { staticStyle: { "font-size": "medium", "font-weight": "600" } },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.editCompanyOverlay.title")) +
                  " "
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "col-md-1 text-center" }, [
          _c("i", {
            staticClass: "bx bx-x",
            staticStyle: { "font-size": "x-large", cursor: "pointer" },
            on: { click: _vm.clickClose },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.isUpdateOrganizationError,
                callback: function ($$v) {
                  _vm.isUpdateOrganizationError = $$v
                },
                expression: "isUpdateOrganizationError",
              },
            },
            [_vm._v(_vm._s(_vm.updateOrganizationErrorMessage))]
          ),
          _c(
            "b-alert",
            {
              staticClass: "mt-3 mb-3",
              attrs: { variant: "success" },
              model: {
                value: _vm.isUpdateOrganizationSuccess,
                callback: function ($$v) {
                  _vm.isUpdateOrganizationSuccess = $$v
                },
                expression: "isUpdateOrganizationSuccess",
              },
            },
            [
              _c("i", { staticClass: "bx bx-check-double bx-tada mr-1" }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("adminComponents.editCompanyOverlay.successMessage")
                  ) +
                  " "
              ),
            ]
          ),
          _c("div", { staticClass: "media" }, [
            _c("div", { staticClass: "avatar-md mr-4" }, [
              _c(
                "span",
                {
                  ref: "organizationAvatarImagePreview",
                  staticClass: "avatar-preview-after-crop",
                },
                [
                  _c("canvas", {
                    ref: "organizationAvatarImageCanvas",
                    staticStyle: { "border-radius": "50%" },
                    attrs: {
                      id: "organizationAvatarImageCanvas",
                      height: "65",
                      width: "65",
                    },
                  }),
                  !_vm.croppedOrganizationImageFile
                    ? _c(
                        "span",
                        {
                          staticClass: "avatar-title bg-white",
                          staticStyle: { "margin-top": "-70px" },
                        },
                        [
                          _vm.organizationImageURL
                            ? _c("img", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  src: _vm.organizationImageURL,
                                  alt: "",
                                  height: "65",
                                },
                              })
                            : _c("img", {
                                staticClass: "rounded-circle",
                                attrs: {
                                  src: "https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg",
                                  alt: "",
                                  height: "65",
                                },
                              }),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]),
            _c("div", { staticClass: "media-body align-self-center" }, [
              _c("div", { staticClass: "text-muted" }, [
                _c("p", { staticClass: "mb-1" }, [
                  _c("input", {
                    ref: "organizationAvatarInput",
                    staticStyle: {
                      width: "0.1px",
                      opacity: "0",
                      "z-index": "-1",
                    },
                    attrs: {
                      id: "organizationAvatarInput",
                      type: "file",
                      name: "file",
                      accept: "image/*",
                    },
                    on: {
                      change: function ($event) {
                        return _vm.organizationAvatarSelected()
                      },
                    },
                  }),
                  _c(
                    "label",
                    {
                      staticClass: "text-info mr-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { for: "organizationAvatarInput" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.editCompanyOverlay.image.label"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("p", { staticClass: "mb-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "adminComponents.editCompanyOverlay.image.description"
                        )
                      ) +
                      " "
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "row mt-4" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "organization-name-group",
                      "label-for": "organizationName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.name.label"
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticClass: "text-danger" }, [
                              _vm._v("*"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      class: { "is-invalid": _vm.$v.name.$error },
                      attrs: {
                        id: "organizationName",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.name.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    }),
                    _vm.$v.name.$error
                      ? _c("div", { staticClass: "invalid-feedback" }, [
                          !_vm.$v.name.required
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "adminComponents.editCompanyOverlay.form.name.required"
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "organization-legal-name-group",
                      "label-for": "organizationLegalName",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.legalName.label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "organizationLegalName",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.legalName.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.legalName,
                        callback: function ($$v) {
                          _vm.legalName = $$v
                        },
                        expression: "legalName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: { id: "vat-number-group", "label-for": "vatNumber" },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.vatNumber.label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "vatNumber",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.vatNumber.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.vatNumber,
                        callback: function ($$v) {
                          _vm.vatNumber = $$v
                        },
                        expression: "vatNumber",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("hr"),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-8" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "organization-street-group",
                      "label-for": "organizationAddress",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.street.label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "organizationAddress",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.street.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.street,
                        callback: function ($$v) {
                          _vm.street = $$v
                        },
                        expression: "street",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "organization-postal-code-group",
                      "label-for": "postalCode",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.postalCode.label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "postalCode",
                        type: "number",
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.postalCode.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.postalCode,
                        callback: function ($$v) {
                          _vm.postalCode = $$v
                        },
                        expression: "postalCode",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "organization-city-group",
                      "label-for": "city",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.city.label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "city",
                        type: "text",
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.city.placeholder"
                        ),
                      },
                      model: {
                        value: _vm.city,
                        callback: function ($$v) {
                          _vm.city = $$v
                        },
                        expression: "city",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "organization-address-group",
                      "label-for": "organizationAddress",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "adminComponents.editCompanyOverlay.form.country.label"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("multiselect", {
                      attrs: {
                        options: _vm.allCountries,
                        placeholder: _vm.$t(
                          "adminComponents.editCompanyOverlay.form.country.placeholder"
                        ),
                        searchable: true,
                        "track-by": "name",
                        label: "name",
                        "custom-label": _vm.customLabelForCountries,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.selectCountry()
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "singleLabel",
                          fn: function ({ option }) {
                            return [_vm._v(" " + _vm._s(option.name) + " ")]
                          },
                        },
                        {
                          key: "option",
                          fn: function ({ option }) {
                            return [_vm._v(" " + _vm._s(option.name) + " ")]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.country,
                        callback: function ($$v) {
                          _vm.country = $$v
                        },
                        expression: "country",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          !_vm.disableSuperAdminFeatures ? _c("hr") : _vm._e(),
          !_vm.disableSuperAdminFeatures
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "organization-owner-group",
                          "label-for": "organizationOwner",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.editCompanyOverlay.form.owner.label"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3101659644
                        ),
                      },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.possibleOrganizationOwners,
                            placeholder: _vm.$t(
                              "adminComponents.editCompanyOverlay.form.owner.placeholder"
                            ),
                            "custom-label": _vm.customLabelFororganizationOwner,
                            "track-by": "id",
                            label: "name",
                            searchable: true,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.selectOrganizationOwner()
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "singleLabel",
                                fn: function ({ option }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(option.name.first) +
                                        " " +
                                        _vm._s(option.name.last) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            836399640
                          ),
                          model: {
                            value: _vm.organizationOwner,
                            callback: function ($$v) {
                              _vm.organizationOwner = $$v
                            },
                            expression: "organizationOwner",
                          },
                        }),
                        _vm.displayValidationErrorForOrganizationOwner
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.editCompanyOverlay.form.owner.required"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "organization-account-manager-group",
                          "label-for": "organizationAccountManager",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.editCompanyOverlay.form.accountManager.label"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2866269775
                        ),
                      },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.accountManagers,
                            placeholder: _vm.$t(
                              "adminComponents.editCompanyOverlay.form.accountManager.placeholder"
                            ),
                            searchable: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.selectAccountManager()
                            },
                          },
                          model: {
                            value: _vm.accountManager,
                            callback: function ($$v) {
                              _vm.accountManager = $$v
                            },
                            expression: "accountManager",
                          },
                        }),
                        _vm.displayValidationErrorForAccountManager
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.editCompanyOverlay.form.accountManager.required"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "organization-support-level-group",
                          "label-for": "organizationSupportLevel",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.editCompanyOverlay.form.supportLevel.label"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          4008061508
                        ),
                      },
                      [
                        _c("multiselect", {
                          attrs: {
                            options: _vm.supportLevels,
                            placeholder: _vm.$t(
                              "adminComponents.editCompanyOverlay.form.supportLevel.placeholder"
                            ),
                            searchable: false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.selectSupportLevel()
                            },
                          },
                          model: {
                            value: _vm.selectedSupportLevel,
                            callback: function ($$v) {
                              _vm.selectedSupportLevel = $$v
                            },
                            expression: "selectedSupportLevel",
                          },
                        }),
                        _vm.displayValidationErrorForSupportLevel
                          ? _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "adminComponents.editCompanyOverlay.form.supportLevel.required"
                                    )
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          id: "organization-comment-group",
                          "label-for": "organizationComment",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "adminComponents.editCompanyOverlay.form.note.label"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3107067157
                        ),
                      },
                      [
                        _c("b-form-textarea", {
                          attrs: {
                            id: "organizationComment",
                            placeholder: _vm.$t(
                              "adminComponents.editCompanyOverlay.form.note.placeholder"
                            ),
                          },
                          model: {
                            value: _vm.comment,
                            callback: function ($$v) {
                              _vm.comment = $$v
                            },
                            expression: "comment",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "row mt-4" }, [
        _c(
          "div",
          { staticClass: "col-md-12" },
          [
            _c(
              "b-button",
              { attrs: { variant: "primary" }, on: { click: _vm.clickSave } },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("adminComponents.editCompanyOverlay.saveButton")
                    ) +
                    " "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm.displayImageCropperModal
        ? _c("OrganizationImageCropperModal", {
            attrs: {
              visible: _vm.displayImageCropperModal,
              imageFile: _vm.selectedOrganizationImageFile,
            },
            on: {
              close: function ($event) {
                _vm.displayImageCropperModal = false
              },
              apply: _vm.setCroppedOrganizationImagePreview,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }