import { render, staticRenderFns } from "./nav-bar.vue?vue&type=template&id=3bd5e45e"
import script from "./nav-bar.vue?vue&type=script&lang=js"
export * from "./nav-bar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Vinura\\Redem\\Development\\ReDem 2.0\\redem-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bd5e45e')) {
      api.createRecord('3bd5e45e', component.options)
    } else {
      api.reload('3bd5e45e', component.options)
    }
    module.hot.accept("./nav-bar.vue?vue&type=template&id=3bd5e45e", function () {
      api.rerender('3bd5e45e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/shared/components/nav-bar.vue"
export default component.exports