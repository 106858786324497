<script>
import { Multiselect } from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import AccountManagers from "@/shared/util/account-managers.json";
import SupportLevels from "@/shared/util/support-levels.json";

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      accountManager: null,
      accountManagers: AccountManagers,
      supportLevel: null,
      supportLevels: SupportLevels,
      notes: null
    }
  },
  validations: {
    accountManager: {
      required
    },
    supportLevel: {
      required
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
      let isValid = !this.$v.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    }
  }
}
</script>
<template>
  <div>
    <div class="form-group row mb-3">
      <div class="col-6">
        <label for="accountManager">
          {{ $t('adminComponents.businessInfoForm.accountManager.label') }}
        <span class="text-danger">*</span>
        </label>
        <multiselect
          id="accountManager"
          v-model="accountManager"
          :options="accountManagers"
          :placeholder="$t('adminComponents.businessInfoForm.accountManager.placeholder')"
        />
      </div>
      <div class="col-6">
        <label for="supportLevel">
          {{ $t('adminComponents.businessInfoForm.supportLevel.label') }}
        <span class="text-danger">*</span>
        </label>
        <multiselect
          id="supportLevel"
          v-model="supportLevel"
          :options="supportLevels"
          :placeholder="$t('adminComponents.businessInfoForm.supportLevel.placeholder')"
        />
      </div>
    </div>
    <div class="form-group row mb-3">
      <div class="mb-3 col-12">
      <label for="notes">
        {{ $t('adminComponents.businessInfoForm.notes.label') }}
      </label>
      <textarea
        id="notes"
        class="form-control"
        v-model="notes"
        :placeholder="$t('adminComponents.businessInfoForm.notes.placeholder')"
        rows="3"
      />
    </div>      
    </div>
  </div>
</template>