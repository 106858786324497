<script>
import { getCurrencyAccoringTolanguage, getCreditsAccoringTolanguage, getDateAccoringTolanguage } from "@/shared/util/helpers.js";

export default {
  props: {
    contract: Object
  },
  methods: {
    formatDate(dateToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getDateAccoringTolanguage(dateToFormat, langaugeToFormat);
    },
    formatCredit(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    formatCurrency(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCurrencyAccoringTolanguage(numberToFormat, langaugeToFormat);
    }
  }
}
</script>

<template>
  <div
    class="custom-contract-card"
    :style="{ minHeight: (!contract.primaryCreditPlan || (contract.primaryCreditPlan && contract.primaryCreditPlan.isPayPerUsage)) ? '120px' : '170px' }"
  >
    <!-- Contract Header -->
    <div class="row">
      <div class="col-8">
        <div style="font-size: medium; font-weight: 600"> {{ contract.type }} </div>
      </div>
      <div class="col-4 text-right">
        <span
          class="badge badge-soft-secondary font-size-12"
          :class="{
            'badge-soft-success': `${contract.status}` === 'ACTIVE',
            'badge-soft-danger': `${contract.status}` === 'EXPIRED',
            'badge-soft-warning': `${contract.status}` === 'UPCOMING'
          }"
        >
          <span v-if="contract.status === 'ACTIVE'"> {{$t("adminComponents.contractsWidget.contractStatus.active")}} </span>
          <span v-if="contract.status === 'EXPIRED'"> {{$t("adminComponents.contractsWidget.contractStatus.expired")}} </span>
          <span v-if="contract.status === 'UPCOMING'"> {{$t("adminComponents.contractsWidget.contractStatus.upcoming")}} </span>
        </span>
      </div>
    </div>

    <!-- Contract Dates -->
    <div class="row mt-3">
      <div class="col-6">
        {{$t("adminComponents.contractsWidget.contractDetails.startDate")}}: <strong> {{ formatDate(contract.startDate) }} </strong>
      </div>

      <div class="col-6">
        {{$t("adminComponents.contractsWidget.contractDetails.endDate")}}: <strong> {{ formatDate(contract.endDate) }} </strong>
      </div>
    </div>

    <!-- Contract Dates -->
    <div class="row mt-2">
      <div class="col-6">
        {{$t("adminComponents.contractsWidget.contractDetails.reference")}}: <strong> {{ contract.name }} </strong>
      </div>
      <div class="col-6" v-if="contract.primaryCreditPlan">
        {{$t("adminComponents.contractsWidget.contractDetails.pricePerCredit")}}: <strong> <i class='bx bx-euro'></i> {{ formatCurrency(contract.primaryCreditPlan.pricePerCredit) }} </strong>
      </div>
    </div>

    <hr v-if="contract.primaryCreditPlan && !contract.primaryCreditPlan.isPayPerUsage"/>

    <!-- Contract Primary Credit Plan -->
    <div class="row mt-3" v-if="contract.primaryCreditPlan && !contract.primaryCreditPlan.isPayPerUsage">
      <div class="col-6">
        {{$t("adminComponents.contractsWidget.contractDetails.received")}}: <strong> {{ formatCredit(contract.primaryCreditPlan.credits.received) }} </strong>
      </div>

      <div class="col-6" v-if="contract.primaryCreditPlan">
        {{$t("adminComponents.contractsWidget.contractDetails.remaning")}}: <strong> {{ formatCredit(contract.primaryCreditPlan.credits.balance) }} </strong>
      </div>
    </div>

  </div>
</template>

<style scoped>
.custom-contract-card {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
}
</style>