<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
import PackageService from "@/api/services/package.service.js";
import contractTypesEnums from "@/shared/enums/contractTypes.js"
import {calculateNumberOfDaysBetweenTwoDates} from "@/shared/util/helpers.js";

export default {
  props: {
    currentActiveContract: Object
  },
  components: {
    Multiselect,
    DatePicker
  },
  data() {
    return {
      contractTypes: [],
      redemPackages: [],
      selectedContractType: null,
      contractStartDate: new Date(new Date().setHours(0, 0, 0, 0)),
      contractEndDate: null,
      isAutoRenew: false,
      disableIsAutoRenew: false
    };
  },
  validations: {
    selectedContractType: { required },
    contractStartDate: { required },
    contractEndDate: { required }
  },
  watch: {
    selectedContractType: {
      immediate: true,
      handler() {
        this.updateContractEndDate();
      }
    },
  },
  async mounted() {
    await this.fetchPackages();

    // set satrt date to next date after current active contract end date
    if(this.currentActiveContract) {
      this.contractStartDate = new Date(this.currentActiveContract.endDate);
      this.contractStartDate.setDate(this.contractStartDate.getDate() + 1);
    }
  },
  methods: {
    async fetchPackages() {
      this.redemPackages = await PackageService.getAllPackages();
      for (const redemPackage of this.redemPackages) {
        this.contractTypes.push(redemPackage.name);
      }
    },
    validate() {
      this.$v.$touch();
      let isValid = !this.$v.$invalid;
      this.$emit('on-validate', this.$data, isValid);
      return isValid;
    },
    disabledStartDates(date) {
      if (this.currentActiveContract && this.currentActiveContract.endDate) {
        return date <= new Date(this.currentActiveContract.endDate);
      }
    },
    disabledEndDates(date) {
      return date < this.contractStartDate;
    },
    updateContractEndDate() {
      if ([contractTypesEnums.STARTER, contractTypesEnums.PROFESSIONAL, contractTypesEnums.ENTERPRISE].includes(this.selectedContractType)) {
        // Set end date to 1 year after start date
        const endDate = new Date(this.contractStartDate);
        endDate.setFullYear(endDate.getFullYear() + 1);
        endDate.setDate(endDate.getDate() - 1);

        this.contractEndDate = endDate;
      } else {
        // For other contract types, reset the end date
        this.contractEndDate = null;
      }
    },
    selectContractType() {
      // Disable isAutoRenew if selected contract type is TEST or CUSTOM
      if(this.selectedContractType === contractTypesEnums.TEST || this.selectedContractType === contractTypesEnums.CUSTOM) {
        this.isAutoRenew = false;
        this.disableIsAutoRenew = true;
      } else {
        this.isAutoRenew = true;
        this.disableIsAutoRenew = false;
      }
      this.updateContractEndDate();
    },
    getNumberOfDaysBetweenTwoDates(startDate, endDate) {
      let modifiedEndDate = new Date(endDate);
      modifiedEndDate.setHours(23, 59, 59, 59);
      return calculateNumberOfDaysBetweenTwoDates(startDate, modifiedEndDate);
    }
  },
};
</script>
<template>
  <div>
    <!-- Contract Type -->
    <div class="form-group row mb-3">
      <div class="col-12">
        <label for="contractType">
          {{ $t("adminComponents.contractInfoForm.contractType.label") }}
        <span class="text-danger">*</span>
        </label>
        <multiselect
          id="contractType"
          v-model="selectedContractType"
          :options="contractTypes"
          :placeholder="$t('adminComponents.contractInfoForm.contractType.placeholder')"
          @select="selectContractType"
          :class="{ 'is-invalid': $v.selectedContractType.$error }"
        />
        <div v-if="$v.selectedContractType.$error" class="invalid-feedback">
          <span v-if="!$v.selectedContractType.required"> {{ $t("adminComponents.contractInfoForm.contractType.requiredError")}} </span>
        </div>
      </div>
    </div>

    <!-- Start date & end date -->
    <div class="form-group row mb-3">
      <div class="col-6">
        <label for="contractStartDate">
          {{ $t("adminComponents.contractInfoForm.startDate.label") }}
          <span class="text-danger">*</span>
        </label>
        <date-picker
          id="contractStartDate"
          v-model="contractStartDate"
          :placeholder="$t('adminComponents.contractInfoForm.startDate.placeholder')"
          format="DD.MM.YYYY"
          :disabled="!currentActiveContract"
          :disabled-date="disabledStartDates"
          :class="{
            'is-invalid': $v.contractStartDate.$error
          }"
        />
        <div v-if="$v.contractStartDate.$error" class="invalid-feedback">
          <span v-if="!$v.contractStartDate.required"> {{ $t("adminComponents.contractInfoForm.startDate.requiredError")}} </span>
        </div>
      </div>
      <div class="col-6">
        <label for="contractEndDate">
          {{ $t("adminComponents.contractInfoForm.endDate.label") }}
          <span class="text-danger">*</span>
        </label>
        <date-picker
          id="contractEndDate"
          v-model="contractEndDate"
          :placeholder="$t('adminComponents.contractInfoForm.endDate.placeholder')"
          format="DD.MM.YYYY"
          :disabled="!contractStartDate"
          :disabled-date="disabledEndDates"
          :class="{
            'is-invalid': $v.contractEndDate.$error
          }"
        />
        <div v-if="$v.contractEndDate.$error" class="invalid-feedback">
          <span v-if="!$v.contractEndDate.required"> {{ $t("adminComponents.contractInfoForm.endDate.requiredError")}} </span>
        </div>
      </div>
    </div>

    <!-- Contract duration -->
    <div v-if="contractEndDate && contractStartDate" class="row mb-4 col-md-12">
      {{ $t("adminComponents.contractInfoForm.days1") }} {{ getNumberOfDaysBetweenTwoDates(contractStartDate,contractEndDate) }} {{ $t("adminComponents.contractInfoForm.days2") }}
    </div>

    <!-- Contract automatic renew -->
    <div class="form-group row mb-4 col-md-12">
      <b-form-checkbox
        v-model="isAutoRenew"
        label="Auto Renew"
        name="autoRenew"
        id="autoRenew"
        :disabled="disableIsAutoRenew"
      >
        {{ $t("adminComponents.contractInfoForm.autoRenew") }}
      </b-form-checkbox>
    </div>

  </div>
</template>

<style scoped>
.is-invalid {
  border-radius: 5px;
  border: 1px solid red;
}
</style>