import { ApiCall } from "../apiBase";
import CustomErrorMessage from "@/shared/util/customErrorMessage.js";

class ContractService {
  async updateTestPlan(contractInput) {
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
        mutation updateTestPlan($input: updateTestPlanInput!) {
          updateTestPlan (input: $input) {
           status
           message
          }
        }
      `,
        variables: {
          input: contractInput
        }
      }
    });

    if (result.data.data.updateTestPlan.status === "SUCCESS") {
      return true;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
  }

  /************* get All Contracts for Compnay*********/
  async getCompanyContracts(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getCompanyContracts($companyId: ID!){
            getCompanyContracts(companyId: $companyId) {
              id
              name
              createdAt
              createdBy
              startDate
              endDate
              status
              assignedCompany
              primaryCreditPlan {
                id
                name
                createdAt
                createdBy
                type
                credits {
                  balance
                  received
                }
                isExpiring
                isPayPerUsage
                pricePerCredit
                expirationDate
                status
                company
              }
              type
            }
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.getCompanyContracts && !response?.data?.errors) {
      return response?.data?.data?.getCompanyContracts;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Get Contract by ID *********/
  async getContractDetails(contractId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query getContractById($contractId: ID!){
            getContractById(contractId: $contractId) {
              id
              name
              createdAt
              createdBy
              startDate
              endDate
              status
              assignedCompany
              primaryCreditPlan {
                id
                name
                createdAt
                createdBy
                type
                credits {
                  balance
                  received
                }
                isExpiring
                isPayPerUsage
                pricePerCredit
                expirationDate
                status
                company
              }
              backupCreditPlan {
                id
                name
                createdAt
                createdBy
                type
                credits {
                  balance
                  received
                }
                isExpiring
                isPayPerUsage
                pricePerCredit
                expirationDate
                status
                company
              }
              type
              autoRenew
              numberOfDatafiles
              numberOfDataRecords
            }
          }
        `,
        variables: {
          contractId: contractId
        }
      }
    });

    if (response?.data?.data?.getContractById && !response?.data?.errors) {
      return response?.data?.data?.getContractById;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Edit Contract *********/
  async editContract(contractInput) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation editContract($contractToEdit: ContractEditInput!) {
            editContract(contractToEdit: $contractToEdit) {
              status
              message
            }
          }
        `,
        variables: {
          contractToEdit: contractInput
        }
      }
    });

    if (response?.data?.data?.editContract && !response?.data?.errors) {
      return response?.data?.data?.editContract;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Delete Contract *********/
  async deleteContract(contractId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation deleteContract($contractId: ID!) {
            deleteContract(contractId: $contractId) {
              status
              message
            }
          }
        `,
        variables: {
          contractId: contractId
        }
      }
    });

    if (response?.data?.data?.deleteContract && !response?.data?.errors) {
      return response?.data?.data?.deleteContract;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }

  /************* Assign Upcoming Contract *********/
  async assignUpcomingContract(contractInput) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          mutation assignUpcomingContractToCompany($contractToAssign: AssignUpcomingContractInput!) {
            assignUpcomingContractToCompany(input: $contractToAssign) { 
              status
              message
            }
          }
        `,
        variables: {
          contractToAssign: contractInput
        }
      }   
    });

    if (response?.data?.data?.assignUpcomingContractToCompany && !response?.data?.errors) {
      return response?.data?.data?.assignUpcomingContractToCompany;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );    
    }
  }

  async isCompnayHavingUpcomingContract(companyId) {
    let response = await ApiCall({
      method: "post",
      data: {
        query: `
          query isCompnayHavingUpcomingContract($companyId: ID!){
            isCompnayHavingUpcomingContract(companyId: $companyId)
          }
        `,
        variables: {
          companyId: companyId
        }
      }
    });

    if (response?.data?.data?.isCompnayHavingUpcomingContract && !response?.data?.errors) {
      return response?.data?.data?.isCompnayHavingUpcomingContract;
    } else {
      throw new CustomErrorMessage(
        response?.data?.errors[0]?.extensions?.code,
        response?.data?.errors[0].message
      );
    }
  }
  
}

export default new ContractService();
