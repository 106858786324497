var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isComponentLoading
        ? _c("div", { staticClass: "row pt-5" }, [
            _c(
              "div",
              { staticClass: "col-md-12 text-center p-5" },
              [
                _c("b-spinner", {
                  staticClass: "m-2",
                  staticStyle: { width: "5rem", height: "5rem" },
                  attrs: { variant: "mid", role: "status" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      !_vm.isComponentLoading && _vm.users && _vm.users.length > 0
        ? _c(
            "div",
            {
              staticClass: "animate__animated animate__fadeInUp animate__slow",
            },
            [
              _c("div", { staticClass: "row mt-3" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _c(
                    "span",
                    {
                      staticClass: "mr-2",
                      staticStyle: {
                        "font-size": "medium",
                        "font-weight": "600",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.manageUsersWidget.activeUsers"
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "col-md-3 text-right" },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "light" },
                        on: { click: _vm.clickInviteUser },
                      },
                      [
                        _c("i", {
                          staticClass: "bx bx-user-plus mr-2",
                          staticStyle: { "font-size": "medium" },
                        }),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.manageUsersWidget.inviteUserButton"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "row mt-2" },
                _vm._l(_vm.activeUsers, function (user, userIndex) {
                  return _c(
                    "div",
                    { key: userIndex, staticClass: "col-md-4" },
                    [
                      _c("ActiveUserCard", {
                        attrs: {
                          user: user,
                          organizationOwner: _vm.organization.companyOwner,
                          loggedInUser: _vm.loggedInUser,
                        },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.pendingUsers && _vm.pendingUsers.length > 0
                ? _c("div", { staticClass: "row mt-3" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "col-md-12" }, [
                      _c(
                        "span",
                        {
                          staticClass: "mr-2",
                          staticStyle: {
                            "font-size": "medium",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "adminComponents.manageUsersWidget.pendingUsers"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.pendingUsers && _vm.pendingUsers.length > 0
                ? _c(
                    "div",
                    { staticClass: "row mt-3" },
                    _vm._l(_vm.pendingUsers, function (user, userIndex) {
                      return _c(
                        "div",
                        { key: userIndex, staticClass: "col-md-4" },
                        [_c("PendingUserCard", { attrs: { user: user } })],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      !_vm.isComponentLoading && _vm.isFetchingDataError
        ? _c("div", { staticClass: "row pt-5" }, [
            _c("div", { staticClass: "col-md-12 text-center" }, [
              _vm._v(" " + _vm._s(this.dataFetchingnErrorMessage) + " "),
            ]),
          ])
        : _vm._e(),
      _vm.isDisplayInviteUserOverlay === true
        ? _c("InviteUserOverlay", {
            attrs: {
              visible: _vm.isDisplayInviteUserOverlay,
              organization: this.organization,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayInviteUserOverlay = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplayDeleteUserOverlay === true
        ? _c("DeleteUserOverlay", {
            attrs: {
              visible: _vm.isDisplayDeleteUserOverlay,
              user: this.userToDelete,
            },
            on: {
              close: function ($event) {
                _vm.isDisplayDeleteUserOverlay = false
              },
            },
          })
        : _vm._e(),
      _vm.isDisplaySendResetPasswordLinkOverlay === true
        ? _c("ResetPasswordLinkOverlay", {
            attrs: {
              visible: _vm.isDisplaySendResetPasswordLinkOverlay,
              user: this.userToSendResetPasswordLink,
            },
            on: {
              close: function ($event) {
                _vm.isDisplaySendResetPasswordLinkOverlay = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-md-12" }, [_c("hr")])
  },
]
render._withStripped = true

export { render, staticRenderFns }