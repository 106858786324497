
<script>
import { getCreditsAccoringTolanguage, getErrorMessageByErrorCode, getCurrencyAccoringTolanguage } from "@/shared/util/helpers.js";
import { CreditBalanceChart } from "../charts.js"
import { EventBus } from "@/shared/util/event-bus.js";
import creditPlanService from "@/api/services/creditPlan.service.js";

export default {
  props: {
    organization: Object
  },
  data() {
    return {
      /***** Data variables *****/
      creditSummary: null,
      creditBalanceChartOptions: null,
      creditBalancePercentage: [0],
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false,
      dataFetchingnErrorMessage: null,
    }
  },
  async created() {
    this.isComponentLoading = true;
    this.creditBalanceChartOptions = new CreditBalanceChart()
    await this.fetchCompanyCreditSummary();
    this.isComponentLoading = false;
    EventBus.$emit("creditAndTransactionComponetGetLoaded");
  },
  methods: {
    async fetchCompanyCreditSummary() {
      try {
        this.creditSummary = await creditPlanService.getCompanyCreditSummary(this.organization.id);

        await this.setValueToChart();
      } catch(error) {
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setValueToChart() {
      this.creditBalancePercentage = []
      this.creditBalancePercentage.push((this.creditSummary.activeCreditPlanBalance / this.creditSummary.activeCreditPlanReceived) * 100);
    },
    formatCredits(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCreditsAccoringTolanguage(numberToFormat, langaugeToFormat);
    },
    formatCurrency(numberToFormat) {
      const langaugeToFormat = window.localStorage.getItem("locale");
      return getCurrencyAccoringTolanguage(numberToFormat, langaugeToFormat);
    }
  }
}
</script>

<template>
  <div class="card" style="min-height: 610px">
    <div class="card-body">
      <div class="card-text">
        
        <div class="row">
          <div class="col-12">
            <span style="font-size: medium; font-weight: 600"> {{$t("adminComponents.creditWidget.title")}} </span>
          </div>
        </div>

        <!-- Wallet Empty -->
        <div class="row mt-5" v-if="this.creditBalancePercentage && !this.creditBalancePercentage[0] && !creditSummary.activePayAsUsageCreditPlanReceived">
          <div class="col-12 text-center">
            <img src="@/assets/images/empty-wallet.png" class="img-fluid mb-4 mt-3" style="width: 60px" />
            <h6>
              {{$t("adminComponents.creditWidget.emptyWallet")}}
            </h6>
          </div>
        </div>

        <!-- Wallet Chart -->
        <div class="row mt-5" v-if="this.creditBalancePercentage && this.creditBalancePercentage[0] && !creditSummary.activePayAsUsageCreditPlanReceived">
          <div class="col-12 text-center">
            <apexchart
              ref="CreditBalanceChart"
              class="apex-charts apexChartData"
              type="radialBar"
              height="300"
              dir="ltr"
              :series="this.creditBalancePercentage"
              :options="creditBalanceChartOptions.chartOptions"
            ></apexchart>
          </div>
        </div>

        <!-- Wallet Data -->
        <div class="row mt-5 mb-5" v-if="this.creditBalancePercentage && this.creditBalancePercentage[0] && !creditSummary.activePayAsUsageCreditPlanReceived">
          <div class="col-6 text-center">
            {{$t("adminComponents.creditWidget.balace.title")}}
            <span v-b-tooltip.hover.right.v-light :title="$i18n.t('adminComponents.creditWidget.balace.description')"> <i class='bx bx-info-circle' ></i> </span>
            <div style="font-size: medium; font-weight: 600"> {{ formatCredits(creditSummary.activeCreditPlanBalance) }} </div>
          </div>
          <div class="col-6 text-center">
            {{$t("adminComponents.creditWidget.receive.title")}}
            <span v-b-tooltip.hover.right.v-light :title="$i18n.t('adminComponents.creditWidget.receive.description')"> <i class='bx bx-info-circle' ></i> </span>
            <div style="font-size: medium; font-weight: 600"> {{ formatCredits(creditSummary.activeCreditPlanReceived) }} </div>
          </div>
        </div>

        <!-- Pay as usage credit summary -->
        <div v-if="creditSummary.activePayAsUsageCreditPlanReceived">
          <!-- Credit Usage -->
          <div class="row mt-5 mb-5 justify-content-center">
            <b-badge class="font-size-12 mb-2" variant="blue2" pill>
                {{ $t("adminComponents.creditWidget.payAsUsage.text") }}
              </b-badge>
            <div class="col-12 text-center">
              {{$t("adminComponents.creditWidget.payAsUsage.creditUsage")}}
              <div style="font-size: xx-large; font-weight: 600"> {{ formatCredits(creditSummary.activePayAsUsageCreditPlanReceived) }} </div>
            </div>
          </div>

          <!-- Payment Inforamtion -->
          <div class="row mt-5 mb-5 justify-content-center">
            <div class="col-10 text-center">
              {{$t("adminComponents.creditWidget.payAsUsage.invoice.title")}}
              <div style="font-size: xx-large; font-weight: 600"> € {{ formatCurrency(creditSummary.activePayAsUsageCreditPlanReceived*creditSummary.pricePerCreditForPayAsUsage) }} </div>
              <small> {{$t("adminComponents.creditWidget.payAsUsage.invoice.description")}} </small>
            </div>
          </div>
        </div>

        <hr/>

        <!-- All time Credit Data -->
        <div class="row mt-4 mb-5">
          <div class="col-6 text-center"> 
            {{$t("adminComponents.creditWidget.totalUsed")}}
            <div style="font-size: medium; font-weight: 600"> {{ formatCredits(creditSummary.totalReceived - creditSummary.totalBalance) }} </div>
          </div>
          <div class="col-6 text-center">
            {{$t("adminComponents.creditWidget.totalReceived")}}
            <div style="font-size: medium; font-weight: 600"> {{ formatCredits(creditSummary.totalReceived) }} </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>