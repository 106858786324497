<script>
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import Countries from "@/shared/util/countries.json"
import OrganizationImageCropperModal from "@/shared/components/model/company-avatar-cropper-modal.vue";
import axios from "axios";
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import ResponseStatus from "@/shared/enums/responseStatus";
import CompanyService from "@/api/services/company.service.js";
import AccountManagers from "@/shared/util/account-managers.json";
import SupportLevels from "@/shared/util/support-levels.json";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean,
    organization: Object,
    candidatesForOrganizationAdmin: Array
  },
  components: {
    Multiselect,
    OrganizationImageCropperModal
  },
  data() {
    return {
      /******** Data Variables ******/
      name: this.organization.name,
      legalName: this.organization.legalName,
      vatNumber: this.organization.VATNumber,
      street: this.organization.address.street,
      postalCode: this.organization.address.postalCode,
      city: this.organization.address.city,
      country: { name: this.organization.address.country},
      accountManager: this.organization.accountManager,
      organizationOwner: this.organization.companyOwner,
      organizationImageURL: this.organization.image,
      comment: this.organization.comment,
      accountManagers: AccountManagers,
      supportLevels: SupportLevels,
      selectedSupportLevel: this.organization.supportLevel,
      possibleOrganizationOwners: this.candidatesForOrganizationAdmin,
      allCountries: Countries,
      selectedOrganizationImageFile: null,
      croppedOrganizationImageFile: null,
      /******** Operational Variables *******/
      disableSuperAdminFeatures: false,
      isUpdateOrganizationSuccess: false,
      isUpdateOrganizationError: false,
      updateOrganizationErrorMessage: null,
      displayImageCropperModal: false,
      displayValidationErrorForAccountManager: false,
      displayValidationErrorForOrganizationOwner: false,
      displayValidationErrorForSupportLevel: false,
    };
  },
  validations: {
    name: { required },
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  mounted() {
    const currentURL = this.$route.fullPath;
    if(currentURL === '/administration') this.disableSuperAdminFeatures = true;
  },
  methods: {
    clickClose() {
      this.$emit("close");
    },
    customLabelForCountries({ code, name }) {
      return `${code} – ${name}`;
    },
    organizationAvatarSelected() {
      const imagePreviewCanvers = this.$refs.organizationAvatarImageCanvas;
      const imagePreviewCanversContext = imagePreviewCanvers.getContext("2d");

      imagePreviewCanversContext.clearRect( 0, 0, imagePreviewCanvers.width, imagePreviewCanvers.height);

      const avatarImageFile = this.$refs.organizationAvatarInput.files[0];
      this.openImageCropperModal(avatarImageFile);
    },
    openImageCropperModal(avatarImageFile) {
      this.selectedOrganizationImageFile = avatarImageFile;
      this.displayImageCropperModal = true;
    },
    async setCroppedOrganizationImagePreview(croppedImage) {
      const imagePreview = this.$refs.organizationAvatarImageCanvas;
      const imagePreviewCanvers = this.$refs.organizationAvatarImageCanvas;
      if (imagePreview && imagePreviewCanvers.getContext) {
        const imagePreviewCanversContext = imagePreviewCanvers.getContext("2d");
        imagePreviewCanversContext.drawImage(croppedImage, 0, 0, 65, 65);
      }
      this.croppedOrganizationImageFile = croppedImage;
      this.displayImageCropperModal = false;

      const preSignedURL = await CompanyService.getCompanyAvatarPreSignedURL(
        this.selectedOrganizationImageFile.name,
        this.organization.id
      );

      if (preSignedURL) {
        const croppedOrganizationImage = this.croppedOrganizationImageFile;
        const originalOrganizationImageFile = this.selectedOrganizationImageFile;
        const organizationImageFile = await new Promise(function(resolve) {
          return croppedOrganizationImage.toBlob(
            resolve,
            originalOrganizationImageFile.type
          );
        });

        // Upload Image to S3
        const getFileUploadResponse = await axios.put(
          preSignedURL.signedUrl,
          organizationImageFile
        );

        // is axios.put work assign file name to file URL
        if (getFileUploadResponse) {
          const fullImageURL = getFileUploadResponse.config.url;
          const splitedtImageURL = fullImageURL.split("/company_avatars")[0];
          this.organizationImageURL =
            splitedtImageURL +
            "/company_avatars/" +
            `${this.organization.id}/` +
            originalOrganizationImageFile.name;
        }
      }
    },
    clickSave() {
      // stop here if form is invalid
      this.$v.$touch();

      if(this.$v.$invalid) {
        this.isUpdateOrganizationError = true;
        this.updateOrganizationErrorMessage = this.$i18n.t("adminComponents.editCompanyOverlay.validationError");
      } else if (!this.accountManager || !this.organizationOwner || !this.selectedSupportLevel) {
        this.isUpdateOrganizationError = true;
        if(!this.accountManager) this.displayValidationErrorForAccountManager = true;
        if(!this.organizationOwner) this.displayValidationErrorForOrganizationOwner = true;
        if(!this.selectedSupportLevel) this.displayValidationErrorForSupportLevel = true;
        this.updateOrganizationErrorMessage = this.$i18n.t("adminComponents.editCompanyOverlay.validationError");
      } else {
        this.updateOrganization();
      }
    },
    selectAccountManager() {
      if(this.accountManager) this.displayValidationErrorForAccountManager = false
    },
    async updateOrganization() {
      const updateOrganizationObject = {
        id: this.organization.id,
        name: this.name,
        legalName: this.legalName,
        VATNumber: this.vatNumber,
        image: this.organizationImageURL,
        address: {
          street: this.street,
          postalCode: this.postalCode,
          city: this.city,
          country: this.country.name,
        },
        accountManager: this.accountManager,
        companyOwner: this.organizationOwner.id,
        supportLevel: this.selectedSupportLevel,
        comment: this.comment,
      }

      try {
        let response = await CompanyService.updateCompany(updateOrganizationObject);
        if (response.status === ResponseStatus.SUCCESS) {
          this.isUpdateOrganizationSuccess = true;
          setTimeout(() => {
            this.isUpdateOrganizationSuccess = false;
            this.$emit("close");
          }, 2000);
        }
      } catch (error) {
        this.isUpdateOrganizationError = true;
        this.updateOrganizationErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    customLabelFororganizationOwner({ name }) {
      // used for mutislect option display with translations
      return name.first + ' ' + name.last;
    },
    selectOrganizationOwner() {
      if(this.organizationOwner) this.displayValidationErrorForOrganizationOwner = false
    },
    selectSupportLevel() {
      if(this.selectedSupportLevel) this.displayValidationErrorForSupportLevel = false
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
    size="md"
  >
    <!-- Title -->
    <div class="row d-flex align-items-center">
      <div class="col-md-11">
        <div style="font-size: medium; font-weight: 600"> {{$t("adminComponents.editCompanyOverlay.title")}} </div>
      </div>
      <div class="col-md-1 text-center">
        <i class='bx bx-x' style="font-size: x-large; cursor: pointer;" @click="clickClose"></i>
      </div>
    </div>

    <!-- Form -->
    <div class="mt-4">
      <!-- Error Message -->
      <b-alert
        v-model="isUpdateOrganizationError"
        class="mt-3 mb-3"
        variant="danger"
        dismissible
        >{{ updateOrganizationErrorMessage }}</b-alert
      >

      <!-- Success Message -->
      <b-alert
        v-model="isUpdateOrganizationSuccess"
        class="mt-3 mb-3"
        variant="success"
      >
        <i class='bx bx-check-double bx-tada mr-1' ></i> {{$t("adminComponents.editCompanyOverlay.successMessage")}}
      </b-alert>

      <div class="media">
        <!-- Display Image Section -->
        <div class="avatar-md mr-4">
          <span
            ref="organizationAvatarImagePreview"
            class="avatar-preview-after-crop"
          >
            <canvas
              id="organizationAvatarImageCanvas"
              ref="organizationAvatarImageCanvas"
              height="65"
              width="65"
              style="border-radius: 50%"
            ></canvas>
            <span
              v-if="!croppedOrganizationImageFile"
              class="avatar-title bg-white"
              style="margin-top: -70px"
            >
              <img
                v-if="organizationImageURL"
                :src="organizationImageURL"
                alt
                class="rounded-circle"
                height="65"
              />
              <img
                v-else
                src="https://redem-resources.s3.eu-central-1.amazonaws.com/default-images/dafault-company.jpg"
                alt
                class="rounded-circle"
                height="65"
              />
            </span>
          </span>
        </div>
        <!-- Image Input Section -->
        <div class="media-body align-self-center">
          <div class="text-muted">
            <p class="mb-1">
              <input
                id="organizationAvatarInput"
                ref="organizationAvatarInput"
                type="file"
                name="file"
                accept="image/*"
                @change="organizationAvatarSelected()"
                style="width: 0.1px; opacity: 0; z-index: -1"
              />
              <label
                for="organizationAvatarInput"
                class="text-info mr-2"
                style="cursor: pointer"
              >
                {{$t("adminComponents.editCompanyOverlay.image.label")}}
              </label>
            </p>
            <p class="mb-1">
              {{$t("adminComponents.editCompanyOverlay.image.description")}}
            </p>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-6">
          <b-form-group id="organization-name-group" label-for="organizationName">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.name.label")}}
              <span class="text-danger">*</span>
            </template>
            <b-form-input
              id="organizationName"
              v-model="name"
              type="text"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.name.placeholder')"
              :class="{ 'is-invalid':  $v.name.$error }"
            ></b-form-input>
            <div v-if="$v.name.$error" class="invalid-feedback">
              <span v-if="!$v.name.required">
                {{$t("adminComponents.editCompanyOverlay.form.name.required")}}
              </span>
            </div>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="organization-legal-name-group" label-for="organizationLegalName">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.legalName.label")}}
            </template>
            <b-form-input
              id="organizationLegalName"
              v-model="legalName"
              type="text"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.legalName.placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="vat-number-group" label-for="vatNumber">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.vatNumber.label")}}
            </template>
            <b-form-input
              id="vatNumber"
              v-model="vatNumber"
              type="text"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.vatNumber.placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <hr/>

      <div class="row">
        <div class="col-md-8">
          <b-form-group id="organization-street-group" label-for="organizationAddress">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.street.label")}}
            </template>
            <b-form-input
              id="organizationAddress"
              v-model="street"
              type="text"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.street.placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-4">
          <b-form-group id="organization-postal-code-group" label-for="postalCode">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.postalCode.label")}}
            </template>
            <b-form-input
              id="postalCode"
              v-model="postalCode"
              type="number"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.postalCode.placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="organization-city-group" label-for="city">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.city.label")}}
            </template>
            <b-form-input
              id="city"
              v-model="city"
              type="text"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.city.placeholder')"
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="organization-address-group" label-for="organizationAddress">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.country.label")}}
            </template>
            <multiselect
              v-model="country"
              :options="allCountries"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.country.placeholder')"
              :searchable="true"
              track-by="name"
              label="name"
              :custom-label="customLabelForCountries"
              @input="selectCountry()"
            >
            <template
              slot="singleLabel"
              slot-scope="{ option }"
            >
              {{ option.name }}
            </template>
            <template slot="option" slot-scope="{ option }">
              {{ option.name }}
            </template>
            </multiselect>
          </b-form-group>
        </div>
      </div>

      <hr v-if="!disableSuperAdminFeatures" />

      <div class="row" v-if="!disableSuperAdminFeatures">
        <div class="col-md-12">
          <b-form-group id="organization-owner-group" label-for="organizationOwner">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.owner.label")}}
              <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="organizationOwner"
              :options="possibleOrganizationOwners"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.owner.placeholder')"
              :custom-label="customLabelFororganizationOwner"
              track-by="id"
              label="name"
              :searchable="true"
              @input="selectOrganizationOwner()"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name.first }} {{ option.name.last }}
              </template>
            </multiselect>
            <small class="text-danger" v-if="displayValidationErrorForOrganizationOwner">
              {{$t("adminComponents.editCompanyOverlay.form.owner.required")}}
            </small>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="organization-account-manager-group" label-for="organizationAccountManager">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.accountManager.label")}}
              <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="accountManager"
              :options="accountManagers"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.accountManager.placeholder')"
              :searchable="false"
              @input="selectAccountManager()"
            >
            </multiselect>
            <small class="text-danger" v-if="displayValidationErrorForAccountManager">
              {{$t("adminComponents.editCompanyOverlay.form.accountManager.required")}}
            </small>
          </b-form-group>
        </div>

        <div class="col-md-6">
          <b-form-group id="organization-support-level-group" label-for="organizationSupportLevel">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.supportLevel.label")}}
              <span class="text-danger">*</span>
            </template>
            <multiselect
              v-model="selectedSupportLevel"
              :options="supportLevels"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.supportLevel.placeholder')"
              :searchable="false"
              @input="selectSupportLevel()"
            >
            </multiselect>
            <small class="text-danger" v-if="displayValidationErrorForSupportLevel">
              {{$t("adminComponents.editCompanyOverlay.form.supportLevel.required")}}
            </small>
          </b-form-group>
        </div>

        <div class="col-md-12">
          <b-form-group id="organization-comment-group" label-for="organizationComment">
            <template v-slot:label>
              {{$t("adminComponents.editCompanyOverlay.form.note.label")}}
            </template>
            <b-form-textarea
              id="organizationComment"
              v-model="comment"
              :placeholder="$t('adminComponents.editCompanyOverlay.form.note.placeholder')"
            ></b-form-textarea>
          </b-form-group>
        </div>
      </div>

    </div>

    <!-- Actions -->
    <div class="row mt-4">
      <div class="col-md-12">
        <b-button variant="primary" @click="clickSave">
          {{$t("adminComponents.editCompanyOverlay.saveButton")}}
        </b-button>
      </div>
    </div>

    <!-- Organization Image Cropper Modal -->
    <OrganizationImageCropperModal
      v-if="displayImageCropperModal"
      :visible="displayImageCropperModal"
      :imageFile="selectedOrganizationImageFile"
      @close="displayImageCropperModal = false"
      @apply="setCroppedOrganizationImagePreview"
    />
  </b-modal>
</template>
