var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isComponentLoading
      ? _c("div", { staticClass: "row pt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-12 text-center p-5" },
            [
              _c("b-spinner", {
                staticClass: "m-2",
                staticStyle: { width: "5rem", height: "5rem" },
                attrs: { variant: "mid", role: "status" },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isComponentLoading && _vm.organizations && _vm.organizations.length > 0
      ? _c("div", { staticClass: "mt-4" }, [
          _c("div", { staticClass: "row mb-4" }, [
            _c(
              "div",
              { staticClass: "col-md-3" },
              [
                _c("b-form-input", {
                  attrs: { type: "search", placeholder: "Search" },
                  model: {
                    value: _vm.filter,
                    callback: function ($$v) {
                      _vm.filter = $$v
                    },
                    expression: "filter",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "table-responsive mb-0" },
            [
              _c("b-table", {
                attrs: {
                  items: _vm.dataForTable,
                  fields: _vm.fieldsForTable,
                  responsive: "sm",
                  hover: "",
                  "per-page": _vm.perPage,
                  "current-page": _vm.currentPage,
                  bordered: true,
                  filter: _vm.filter,
                  "filter-included-fields": ["organization"],
                },
                on: {
                  "row-clicked": function ($event) {
                    return _vm.clickOrganizationinfo($event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(organization)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "row align-items-center" }, [
                            _c("div", { staticClass: "col-md-12" }, [
                              _c("img", {
                                staticClass: "rounded-circle",
                                staticStyle: { height: "30px" },
                                attrs: { src: data.value.image },
                              }),
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "600" } },
                                [_vm._v(" " + _vm._s(data.value.name) + " ")]
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(wallet)",
                      fn: function ({ item }) {
                        return [
                          item.activeCreditOverview.totalActiveBalance
                            ? [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatCredit(
                                        item.activeCreditOverview
                                          .totalActiveBalance
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            : item.activeCreditOverview
                                .totalReceivedFromPayAsUsage
                            ? [
                                _c(
                                  "b-badge",
                                  {
                                    staticClass: "font-size-12 mb-2",
                                    attrs: { variant: "blue2", pill: "" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "adminComponents.creditPlansWidget.payAsUsage"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _vm._v(
                                  " - " +
                                    _vm._s(
                                      _vm.formatCredit(
                                        item.activeCreditOverview
                                          .totalReceivedFromPayAsUsage
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            : [_vm._v(" 0 ")],
                        ]
                      },
                    },
                    {
                      key: "cell(walletBalanceAsPercentage)",
                      fn: function ({ item }) {
                        return [
                          item.activeCreditOverview.totalActiveBalancePercentage
                            ? _c(
                                "b-badge",
                                {
                                  staticClass: "font-size-12 mb-2",
                                  attrs: {
                                    variant: _vm.getBalancePercentageVariant(
                                      item.activeCreditOverview
                                        .totalActiveBalancePercentage
                                    ),
                                    pill: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        Math.round(
                                          item.activeCreditOverview
                                            .totalActiveBalancePercentage
                                        )
                                      ) +
                                      "% "
                                  ),
                                ]
                              )
                            : _c("span", [_vm._v(" - ")]),
                        ]
                      },
                    },
                    {
                      key: "cell(organizationStatus)",
                      fn: function (data) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-soft-secondary font-size-12",
                              class: {
                                "badge-soft-warning":
                                  `${data.value}` ===
                                  _vm.activationStatus.PENDING,
                                "badge-soft-success":
                                  `${data.value}` ===
                                  _vm.activationStatus.ACTIVATED,
                                "badge-soft-danger":
                                  `${data.value}` ===
                                  _vm.activationStatus.DEACTIVATED,
                              },
                            },
                            [
                              data.value === _vm.activationStatus.PENDING
                                ? _c("span", [_vm._v(" PENDING ")])
                                : _vm._e(),
                              data.value === _vm.activationStatus.ACTIVATED
                                ? _c("span", [_vm._v(" ACTIVATED ")])
                                : _vm._e(),
                              data.value === _vm.activationStatus.DEACTIVATED
                                ? _c("span", [_vm._v(" DEACTIVATED ")])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(contractType)",
                      fn: function (scope) {
                        return [
                          scope.item.remainingDays >= 0
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "b-badge",
                                    {
                                      staticClass: "font-size-12 mb-2",
                                      attrs: {
                                        variant: _vm.getContractTypeVariant(
                                          scope.item.contractType
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(scope.item.contractType) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _c("span", { staticClass: "ml-1" }, [
                                _vm._v(" - "),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(contractStartDate)",
                      fn: function (scope) {
                        return [
                          scope.item.remainingDays >= 0
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDate(
                                        scope.item.contractStartDate
                                      )
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v(" - ")]),
                        ]
                      },
                    },
                    {
                      key: "cell(contractEndDate)",
                      fn: function (scope) {
                        return [
                          scope.item.remainingDays >= 0
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatDate(scope.item.contractEndDate)
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("span", [_vm._v(" - ")]),
                        ]
                      },
                    },
                    {
                      key: "cell(remainingDays)",
                      fn: function (scope) {
                        return [
                          new Date(scope.item.contractStartDate) > new Date()
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "badge badge-soft-factor font-size-12",
                                },
                                [_vm._v("Not yet started")]
                              )
                            : scope.item.remainingDays < 0
                            ? _c("span", [_vm._v(" - ")])
                            : _c("span", [
                                _vm._v(
                                  " " + _vm._s(scope.item.remainingDays) + " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "cell(supportLevel)",
                      fn: function (data) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass:
                                "badge badge-soft-secondary font-size-12 rounded-pill",
                              class: {
                                "badge-soft-success": `${data.value}` === "A",
                                "badge-soft-blue2": `${data.value}` === "B",
                                "badge-soft-factor": `${data.value}` === "C",
                              },
                            },
                            [_vm._v(" " + _vm._s(data.value) + " ")]
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(latestDataImport)",
                      fn: function (data) {
                        return [
                          data.value
                            ? _c("span", [
                                data.value.daysAgo === 0
                                  ? _c("span", [_vm._v(" Today ")])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.value.daysAgo) +
                                          " days ago "
                                      ),
                                    ]),
                              ])
                            : _c("span", [_vm._v(" - ")]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2113944330
                ),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                {
                  staticClass:
                    "dataTables_paginate paging_simple_numbers float-right",
                },
                [
                  _c(
                    "ul",
                    { staticClass: "pagination pagination-rounded mb-0" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          "total-rows": _vm.rows,
                          "per-page": _vm.perPage,
                        },
                        on: { change: _vm.changePagination },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }