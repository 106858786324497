var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card", staticStyle: { "min-height": "610px" } },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("div", { staticClass: "card-text" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "span",
                {
                  staticStyle: { "font-size": "medium", "font-weight": "600" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("adminComponents.creditWidget.title")) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
          this.creditBalancePercentage &&
          !this.creditBalancePercentage[0] &&
          !_vm.creditSummary.activePayAsUsageCreditPlanReceived
            ? _c("div", { staticClass: "row mt-5" }, [
                _c("div", { staticClass: "col-12 text-center" }, [
                  _c("img", {
                    staticClass: "img-fluid mb-4 mt-3",
                    staticStyle: { width: "60px" },
                    attrs: { src: require("@/assets/images/empty-wallet.png") },
                  }),
                  _c("h6", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("adminComponents.creditWidget.emptyWallet")
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          this.creditBalancePercentage &&
          this.creditBalancePercentage[0] &&
          !_vm.creditSummary.activePayAsUsageCreditPlanReceived
            ? _c("div", { staticClass: "row mt-5" }, [
                _c(
                  "div",
                  { staticClass: "col-12 text-center" },
                  [
                    _c("apexchart", {
                      ref: "CreditBalanceChart",
                      staticClass: "apex-charts apexChartData",
                      attrs: {
                        type: "radialBar",
                        height: "300",
                        dir: "ltr",
                        series: this.creditBalancePercentage,
                        options: _vm.creditBalanceChartOptions.chartOptions,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          this.creditBalancePercentage &&
          this.creditBalancePercentage[0] &&
          !_vm.creditSummary.activePayAsUsageCreditPlanReceived
            ? _c("div", { staticClass: "row mt-5 mb-5" }, [
                _c("div", { staticClass: "col-6 text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("adminComponents.creditWidget.balace.title")
                      ) +
                      " "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.v-light",
                          modifiers: {
                            hover: true,
                            right: true,
                            "v-light": true,
                          },
                        },
                      ],
                      attrs: {
                        title: _vm.$i18n.t(
                          "adminComponents.creditWidget.balace.description"
                        ),
                      },
                    },
                    [_c("i", { staticClass: "bx bx-info-circle" })]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "medium",
                        "font-weight": "600",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatCredits(
                              _vm.creditSummary.activeCreditPlanBalance
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "col-6 text-center" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("adminComponents.creditWidget.receive.title")
                      ) +
                      " "
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right.v-light",
                          modifiers: {
                            hover: true,
                            right: true,
                            "v-light": true,
                          },
                        },
                      ],
                      attrs: {
                        title: _vm.$i18n.t(
                          "adminComponents.creditWidget.receive.description"
                        ),
                      },
                    },
                    [_c("i", { staticClass: "bx bx-info-circle" })]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "medium",
                        "font-weight": "600",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.formatCredits(
                              _vm.creditSummary.activeCreditPlanReceived
                            )
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.creditSummary.activePayAsUsageCreditPlanReceived
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "row mt-5 mb-5 justify-content-center" },
                  [
                    _c(
                      "b-badge",
                      {
                        staticClass: "font-size-12 mb-2",
                        attrs: { variant: "blue2", pill: "" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.creditWidget.payAsUsage.text"
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "col-12 text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.creditWidget.payAsUsage.creditUsage"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "xx-large",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatCredits(
                                  _vm.creditSummary
                                    .activePayAsUsageCreditPlanReceived
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "row mt-5 mb-5 justify-content-center" },
                  [
                    _c("div", { staticClass: "col-10 text-center" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "adminComponents.creditWidget.payAsUsage.invoice.title"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "xx-large",
                            "font-weight": "600",
                          },
                        },
                        [
                          _vm._v(
                            " € " +
                              _vm._s(
                                _vm.formatCurrency(
                                  _vm.creditSummary
                                    .activePayAsUsageCreditPlanReceived *
                                    _vm.creditSummary
                                      .pricePerCreditForPayAsUsage
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c("small", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "adminComponents.creditWidget.payAsUsage.invoice.description"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _c("hr"),
          _c("div", { staticClass: "row mt-4 mb-5" }, [
            _c("div", { staticClass: "col-6 text-center" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.creditWidget.totalUsed")) +
                  " "
              ),
              _c(
                "div",
                {
                  staticStyle: { "font-size": "medium", "font-weight": "600" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatCredits(
                          _vm.creditSummary.totalReceived -
                            _vm.creditSummary.totalBalance
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
            _c("div", { staticClass: "col-6 text-center" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("adminComponents.creditWidget.totalReceived")) +
                  " "
              ),
              _c(
                "div",
                {
                  staticStyle: { "font-size": "medium", "font-weight": "600" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatCredits(_vm.creditSummary.totalReceived)
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }