<script>
import contractService from '@/api/services/contract.service';
import ContractCard from './contract-card.vue';
import { getErrorMessageByErrorCode } from "@/shared/util/helpers.js";
import { EventBus } from "@/shared/util/event-bus.js";
import ContractStatus from '@/shared/enums/contractStatus';
import simplebar from "simplebar-vue";

export default {
  props: {
    organization: Object,
    isSuperAdminComponent: Boolean
  },
  components: {
    ContractCard,
    simplebar
  },
  data() {
    return {
      /***** Data variables *****/
      contracts: null,
      contractsToDisplay: [],
      showAllActiveContract: true,
      showAllUpcomingContracts: true,
      showAllExpiredContracts: true,
      organizationActiveContract: null,
      selectedContractToDisplay: null,
      contractToHightlight: null,
      /****** Operational variable ******/
      isComponentLoading: false,
      isFetchingDataError: false
    }
  },
  created() {
    EventBus.$on("refreshOrganizationContractsWidgets", async (contractToRefresh) => {
      this.selectedContractToDisplay = contractToRefresh;
      await this.fetchOrganizationContracts();
    })
  },
  async mounted() {
    await this.fetchOrganizationContracts();
  },
  methods: {
    async fetchOrganizationContracts() {
      this.isComponentLoading = true;
      try {
        this.contracts = null;

        this.contracts = await contractService.getCompanyContracts(this.organization.id);
        this.organizationActiveContract = this.contracts.find(contract => contract.status === ContractStatus.ACTIVE);
        EventBus.$emit("organizationActiveContract", this.organizationActiveContract);

        await this.setDataForDisplay();
        this.isComponentLoading = false;
        EventBus.$emit("contractComponetGetLoaded");
      } catch(error) {
        this.isComponentLoading = false;
        this.isFetchingDataError = true;
        this.dataFetchingnErrorMessage = await getErrorMessageByErrorCode(error.code);
      }
    },
    setDataForDisplay() {
      this.contractsToDisplay = []

      this.contracts.forEach(contract => {
        if(this.showAllActiveContract && contract.status === ContractStatus.ACTIVE) {
          this.contractsToDisplay.push(contract);
        }

        // show upcoming contracts only if the user is super admin
        if(this.showAllUpcomingContracts && contract.status === ContractStatus.UPCOMING && this.isSuperAdminComponent) {
          this.contractsToDisplay.push(contract);
        }

        if(this.showAllExpiredContracts && contract.status === ContractStatus.EXPIRED) {
          this.contractsToDisplay.push(contract);
        }
      });

      // set default selected contract to display details
      const defaultContract = this.contracts[0];

      if (this.selectedContractToDisplay) {
        const selectedContractExists = this.contracts.some(contract => contract.name === this.selectedContractToDisplay.name);

        if (selectedContractExists) {
          this.contractToHightlight = this.selectedContractToDisplay;
        } else {
          this.selectedContractToDisplay = defaultContract;
          this.contractToHightlight = defaultContract;
        }
      } else {
        this.selectedContractToDisplay = defaultContract;
        this.contractToHightlight = defaultContract;
      }

      // set default selected contract to display details
      EventBus.$emit("chanageSelectedContractToDisplayDetails", this.selectedContractToDisplay ? this.selectedContractToDisplay : this.contracts[0]);
    },
    selectContractToDisplayDetails(contractToDisplayDetails) {
      this.selectedContractToDisplay = contractToDisplayDetails;
      EventBus.$emit("chanageSelectedContractToDisplayDetails", this.selectedContractToDisplay);

      this.contractToHightlight = this.selectedContractToDisplay;
    },
  }
}
</script>

<template>
  <div class="card" style="height: 610px">
    <div class="card-body">
      <div class="card-text">

        <div class="row align-items-center">
          <div class="col-4">
            <span style="font-size: medium; font-weight: 600"> {{$t("adminComponents.contractsWidget.title")}} </span>
          </div>
          <div class="col-8 text-right">
            <b-form-checkbox
              v-model="showAllActiveContract"
              @change="setDataForDisplay"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.contractsWidget.contractStatus.active")}}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="showAllUpcomingContracts"
              @change="setDataForDisplay"
              v-if="isSuperAdminComponent"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.contractsWidget.contractStatus.upcoming")}}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="showAllExpiredContracts"
              @change="setDataForDisplay"
              style="display: inline-block; margin-right: 10px; text-align: right;"
            >
              {{$t("adminComponents.contractsWidget.contractStatus.expired")}}
            </b-form-checkbox>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-12">
            <simplebar style="max-height: 520px; overflow-x: hidden;">
              <div class="mt-2" v-for="(contract, contractIndex) in contractsToDisplay" :key="contractIndex" @click="selectContractToDisplayDetails(contract)">
                <ContractCard :contract="contract" :style="{ backgroundColor: contract.name === contractToHightlight.name ? '#f7f8fd' : null }"/>
              </div>
            </simplebar>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>